<template>
  <section>
    <div class="rounded-lg bg-white shadow">
      <div class="px-3 py-4 flex justify-between items-center">
        <span class="text-lg leading-6 font-medium text-gray-900">
          Queues
        </span>
        <div class="flex items-center">
          <span v-if="loading" class="relative">
            <ClockIcon
              class="animate-spin h-5 w-5 text-gray-700"
              aria-hidden="true"
            />
          </span>
          <Menu as="span" class="relative block">
            <MenuButton
              class="
                inline-flex
                items-center
                rounded-r-md
                text-sm
                font-medium
                hover:bg-gray-100
                focus:outline-none
                border-2
              "
            >
              <span class="py-2 pl-2 pr-24" v-if="queue">{{ queue.name }}</span>
              <span class="py-2 pl-2 pr-32" v-if="queue === null"
                >Select Queue</span
              >
              <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="
                  z-50
                  origin-top-right
                  absolute
                  right-0
                  mt-2
                  -mr-1
                  rounded-md
                  shadow-lg
                  bg-white
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                "
              >
                <div class="py-1">
                  <MenuItem
                    v-for="queueItem in queues"
                    :key="queueItem.id"
                    v-slot="{ active }"
                    @click="selectQueue(queueItem.id)"
                  >
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full',
                      ]"
                    >
                      {{ queueItem.name }}
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>

      <div v-if="queue?.calls.length === 0" class="mx-3 mb-2">
        <EmptyTable label="You do not have any recent calls." />
      </div>

      <div
        v-if="queue"
        class="h-56 overflow-y-scroll"
        @drop="onDrop($event, 'created')"
        @dragenter.prevent
        @dragover.prevent
      >
        <QueueCallPanelTable :status="'created'" />
      </div>
      <div class="grid grid-cols-2 gap-4 items-start" v-if="queue">
        <div
          class="h-56 overflow-y-scroll"
          v-for="status in ['on_hold', 'completed']"
          :key="status"
          @drop="onDrop($event, status)"
          @dragenter.prevent
          @dragover.prevent
        >
          <QueueCallPanelTable :status="status" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import QueueCallPanelTable from "@/modules/queues/views/queue_call_panel_table.vue";

import { mapGetters, mapActions } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, ClockIcon } from "@heroicons/vue/solid";
import queuesApi from "@/network/refactored/queues";

export default {
  components: {
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    ChevronDownIcon,
    ClockIcon,
    EmptyTable,
    QueueCallPanelTable,
  },
  created() {
    this.listQueues();
  },
  methods: {
    async onDrop(event, status) {
      const callID = event.dataTransfer.getData("id");
      const oldStatus = event.dataTransfer.getData("status");
      if (status === oldStatus) return;

      await queuesApi.updateQueueCallStatus(callID, status);
      this.updateQueueCallStatus({ callID: callID, status: status });
    },
    selectQueue(queueID) {
      this.getQueueDetails(queueID);
    },
    ...mapActions("queues", [
      "listQueues",
      "getQueueDetails",
      "updateQueueCallStatus",
    ]),
    ...mapActions("snackbarStore", ["errorMessage"]),
  },
  computed: {
    ...mapGetters("queues", ["queues", "loading", "queue"]),
  },
};
</script>
