<template>
  <h4 class="text-center">{{ statusText }}</h4>
  <div v-if="callsList.length === 0" class="mx-3 mb-2">
    <EmptyTable :label="`You do not have any ${statusText} calls.`" />
  </div>
  <table
    class="min-w-full divide-y divide-gray-200"
    v-if="callsList.length > 0"
  >
    <thead class="bg-gray-100">
      <tr>
        <th
          scope="col"
          class="
            px-6
            py-3
            text-left text-xs
            font-medium
            text-gray-500
            uppercase
            tracking-wider
          "
        >
          Number
        </th>
        <th
          scope="col"
          class="
            px-6
            py-3
            text-left text-xs
            font-medium
            text-gray-500
            uppercase
            tracking-wider
          "
        >
          Notes
        </th>
        <th scope="col" class="relative px-6 py-3">
          <ClockIcon
            v-if="loading"
            class="animate-spin h-4. w-5 text-gray-700"
            aria-hidden="true"
          />
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <QueueCallPanelItem
        v-for="call in callsList"
        :key="call.id"
        :call="call"
        @call="callQueueItem"
        @updateStatus="updateCallStatus"
        :draggable="userType !== 'listen_only'"
        @dragstart="startDragging($event, call)"
      />
    </tbody>
  </table>

  <PlaceCallDialog
    ref="dialog"
    @close="placeCallDialog = false"
    :opened="placeCallDialog"
  />

  <UpdateCallStatusDialog
    ref="statusDialog"
    @close="updateCallStatusDialogOpen = false"
    :opened="updateCallStatusDialogOpen"
    :currentStatus="status"
  />
</template>

<script>
import QueueCallPanelItem from "@/modules/queues/views/queue_call_panel_item.vue";
import PlaceCallDialog from "@/widgets/livecall/rooms/active-room/PlaceCallDialog.vue";
import { ClockIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import UpdateCallStatusDialog from "@/widgets/queue/UpdateCallStatusDialog";
import EmptyTable from "@/widgets/common/EmptyTable";
import _ from "lodash";
import StorageUtil from "@/utils/LocalStorageUtil";

export default {
  components: {
    UpdateCallStatusDialog,
    QueueCallPanelItem,
    PlaceCallDialog,
    ClockIcon,
    EmptyTable,
  },
  data() {
    return {
      placeCallDialog: false,
      updateCallStatusDialogOpen: false,
      statusText: _.startCase(this.status),
    };
  },
  props: {
    status: {
      type: String,
    },
  },
  methods: {
    startDragging(event, item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("id", item.id);
      event.dataTransfer.setData("status", item.status);
    },
    callQueueItem(call) {
      this.placeCallDialog = true;
      this.$refs.dialog.prepare(call);
    },
    updateCallStatus(call) {
      this.updateCallStatusDialogOpen = true;
      this.$refs.statusDialog.setCall(call);
    },
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    callsList() {
      if (this.queue)
        return this.queue.calls.filter((call) => call.status === this.status);
      return [];
    },
    ...mapGetters("queues", ["loading", "queue"]),
  },
};
</script>
