<template>
  <div class="rounded-md bg-gray-100 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <InformationCircleIcon
          class="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm text-gray-700">{{ label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/solid";
export default {
  components: {
    InformationCircleIcon,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
