<template>
  <TransitionRoot as="template" :show="call !== null">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      :open="call !== null"
      @close="close"
    >
      <div
        class="flex items-end justify-center min-h-screen text-center sm:block"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              transform
              transition-all
              sm:align-middle sm:max-w-xl sm:w-full sm:p-4
            "
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-3">
              <button
                @click="close"
                type="button"
                class="
                  bg-white
                  rounded-md
                  text-gray-400
                  hover:text-gray-500
                  focus:outline-none
                "
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div class="bg-white">
              <div class="">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Call Note
                </h3>
                <div class="mt-2 text-sm text-gray-500">
                  <p>Enter note or alias for the call</p>
                </div>
                <form class="mt-5">
                  <div class="w-full">
                    <textarea
                      v-model="note"
                      rows="3"
                      class="
                        shadow-sm
                        block
                        w-full
                        focus:ring-indigo-500 focus:border-indigo-500
                        sm:text-sm
                        border border-gray-300
                        rounded-md
                      "
                    />
                  </div>
                  <div class="w-full pt-3 flex justify-end">
                    <button
                      :disabled="loading"
                      @click="save"
                      type="submit"
                      class="
                        w-full
                        px-4
                        py-2
                        border border-transparent
                        shadow-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-gray-700
                        hover:bg-gray-800
                        focus:outline-none
                        sm:w-auto sm:text-sm
                      "
                    >
                      {{ loading ? "wait.." : "Save" }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { XIcon } from "@heroicons/vue/outline";

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  data() {
    return {
      newNote: "",
    };
  },
  methods: {
    close() {
      if (this.loading) return;
      this.newNote = "";
      this.setCall({ call: null });
    },
    async save(e) {
      e.preventDefault();
      if (this.loading) return;
      if (this.newNote === "") return;
      await this.saveNote({ note: this.newNote });
      this.close();
    },
    ...mapActions("callNotes", ["setCall", "saveNote"]),
  },
  computed: {
    ...mapGetters("callNotes", ["call", "loading"]),
    note: {
      get: function () {
        return this.call?.notes;
      },
      set: function (value) {
        this.newNote = value;
      },
    },
  },
};
</script>
