<template>
  <tr>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex">
        <div>
          <div class="text-sm font-medium text-gray-900">
            <span>{{ call.caller_id_name ?? "N/A" }}</span>
          </div>
          <div
            class="text-sm text-gray-500 w-40 overflow-x-hidden"
            :title="call.caller_id_num"
          >
            {{ call.caller_id_num }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex justify-center text-sm text-gray-500">
        {{
          userType === "listen_only" && call.number.length > 3
            ? call.number.substring(0, 3) +
              new Array(call.number.length - 3).join("*")
            : call.number
        }}
      </div>
    </td>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex justify-center items-center">
        <div>
          <div class="text-sm flex text-gray-500">
            <button
              v-if="userType !== 'listen_only'"
              @click="$emit('recall', call)"
              type="button"
              class="
                inline-flex
                items-center
                px-2.5
                py-1.5
                border border-gray-300
                shadow-sm
                text-xs
                font-medium
                rounded
                text-gray-700
                bg-white
                hover:bg-gray-50
                focus:outline-none
              "
            >
              RE CALL
            </button>
            <button
              v-if="userType !== 'listen_only'"
              @click="openNotesDialog"
              :title="call.notes"
              type="button"
              class="
                inline-flex
                ml-2
                items-center
                p-1
                border border-transparent
                rounded-full
                shadow-sm
                text-indigo-700
                bg-indigo-100
                hover:bg-indigo-200
                focus:outline-none
              "
            >
              <AnnotationIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              :title="call.hangup_reason"
              type="button"
              class="
                inline-flex
                ml-2
                items-center
                p-1
                border border-transparent
                rounded-full
                shadow-sm
                text-indigo-700
                bg-indigo-100
                hover:bg-indigo-200
                focus:outline-none
              "
            >
              <InformationCircleIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import { AnnotationIcon, InformationCircleIcon } from "@heroicons/vue/solid";
import StorageUtil from "@/utils/LocalStorageUtil.js";
export default {
  components: {
    AnnotationIcon,
    InformationCircleIcon,
  },
  emits: ["recall"],
  props: {
    call: {
      type: Object,
      default: null,
    },
  },
  methods: {
    openNotesDialog() {
      this.setCall({ call: this.call });
    },
    ...mapActions("callNotes", ["setCall"]),
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
  },
};
</script>
