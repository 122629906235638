<template>
  <li class="bg-white border rounded-lg mt-2 shadow-sm">
    <div class="relative group px-4 py-3 flex items-center">
      <div class="-m-1 flex-1 block p-1">
        <div class="absolute inset-0" aria-hidden="true" />
        <div class="flex-1 flex items-center min-w-0 relative">
          <span
            class="
              mr-2
              inline-flex
              items-center
              px-2.5
              py-0.5
              rounded-md
              text-sm
              font-medium
              bg-yellow-100
              text-yellow-800
            "
          >
            {{ channel.call_status }}
          </span>
          <a
            v-if="userType !== 'listen_only'"
            @click="record"
            href="#"
            class="
              relative
              inline-flex
              items-center
              rounded-full
              border border-gray-300
              px-3
              py-0.5
              text-sm
            "
          >
            <span
              class="absolute flex-shrink-0 flex items-center justify-center"
            >
              <span
                v-if="channel?.recording_status === 'on'"
                class="
                  animate-ping
                  absolute
                  inline-flex
                  h-2.5
                  w-2.5
                  rounded-full
                  bg-red-500
                  opacity-75
                "
              ></span>
              <span
                :class="[
                  channel?.recording_status === 'on'
                    ? 'bg-red-500'
                    : 'bg-gray-400',
                  'h-2 w-2 rounded-full',
                ]"
                aria-hidden="true"
              />
            </span>
            <span
              :class="[
                channel?.recording_status === 'on'
                  ? 'text-red-500'
                  : 'text-gray-400',
                'ml-4 font-medium',
              ]"
              >REC</span
            >
          </a>
          <button
            v-if="userType !== 'listen_only'"
            @click="
              pauseRecording({
                callId: channel?.id,
                status: !(channel?.isRecordingPaused ?? false),
              })
            "
            :disabled="channel?.recording_status !== 'on'"
            title="Pause Recording"
            type="button"
            :class="[
              channel?.recording_status !== 'on'
                ? 'text-indigo-200'
                : 'text-indigo-600 hover:bg-indigo-200',
              'ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-s bg-indigo-100 focus:outline-none',
            ]"
          >
            <PauseIcon
              v-if="!channel?.isRecordingPaused"
              class="h-5 w-5"
              aria-hidden="true"
            />
            <PlayIcon
              v-if="channel?.isRecordingPaused"
              class="h-5 w-5"
              aria-hidden="true"
            />
          </button>
          <button
            v-if="userType !== 'listen_only'"
            @click="
              muteRecording({
                callId: channel?.id,
                status: !(channel?.isRecordingMuted ?? false),
              })
            "
            :disabled="channel?.recording_status !== 'on'"
            title="Mute Recording"
            type="button"
            :class="[
              channel?.recording_status !== 'on'
                ? 'text-indigo-200'
                : 'text-indigo-600 hover:bg-indigo-200',
              'ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-s bg-indigo-100 focus:outline-none',
            ]"
          >
            <StatusOnlineIcon
              v-if="!channel?.isRecordingMuted"
              class="h-5 w-5"
              aria-hidden="true"
            />
            <StatusOfflineIcon
              v-if="channel?.isRecordingMuted"
              class="h-5 w-5"
              aria-hidden="true"
            />
          </button>
          <div class="ml-4 truncate">
            <p class="text-sm font-medium text-gray-900 truncate">
              <span
                v-if="channel?.caller_id_name"
                :class="['text-red-500 text-sm']"
                >({{ channel?.caller_id_name }})</span
              >

              {{
                userType === "listen_only" && channel.number.length > 3
                  ? channel.number.substring(0, 3) +
                    new Array(channel.number.length - 3).join("*")
                  : channel.number
              }}

              <span
                v-if="channel?.recording_status === 'on'"
                :class="['text-red-500 text-sm']"
                >(Primary)</span
              >

              <span
                v-if="
                  channel?.recording_status !== 'on' &&
                  isRoomRecording(channel?.bridge_id)
                "
                :class="['text-gray-400 text-sm']"
                >(Secondary)</span
              >
            </p>
            <p class="text-sm text-gray-500 truncate">
              (CID:{{ channel.caller_id_num }})
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="userType !== 'listen_only'"
        class="ml-2 flex-shrink-0 relative inline-block text-left"
      >
        <button
          @click="
            muteCall({
              callId: channel?.id,
              status: !(channel?.isMuted ?? false),
            })
          "
          title="Mute"
          type="button"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-transparent
            text-xs
            font-medium
            rounded
            text-indigo-700
            bg-indigo-100
            hover:bg-indigo-200
            focus:outline-none
          "
        >
          <VolumeUpIcon
            v-if="!channel?.isMuted"
            class="h-4 w-4"
            aria-hidden="true"
          />
          <VolumeOffIcon
            v-if="channel?.isMuted"
            class="h-4 w-4"
            aria-hidden="true"
          />
        </button>
        <button
          @click="setDMTF({ callId: channel?.id })"
          title="DMTF"
          type="button"
          class="
            ml-2
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-transparent
            text-xs
            font-medium
            rounded
            text-indigo-700
            bg-indigo-100
            hover:bg-indigo-200
            focus:outline-none
          "
        >
          <CalculatorIcon class="h-4 w-4" aria-hidden="true" />
        </button>
        <button
          title="Kick"
          @click="kick"
          type="button"
          class="
            ml-2
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-transparent
            text-xs
            font-medium
            rounded
            text-indigo-700
            bg-indigo-100
            hover:bg-indigo-200
            focus:outline-none
          "
        >
          <UserRemoveIcon class="h-4 w-4" aria-hidden="true" />
        </button>
        <button
          @click="hangupCall"
          title="Hangup"
          type="button"
          class="
            ml-2
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-transparent
            text-xs
            font-medium
            rounded
            text-indigo-700
            bg-indigo-100
            hover:bg-indigo-200
            focus:outline-none
          "
        >
          <PhoneMissedCallIcon class="h-4 w-4" aria-hidden="true" />
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StorageUtil from "@/utils/LocalStorageUtil.js";
import {
  VolumeUpIcon,
  CalculatorIcon,
  PhoneMissedCallIcon,
  UserRemoveIcon,
  VolumeOffIcon,
  PauseIcon,
  PlayIcon,
  StatusOnlineIcon,
  StatusOfflineIcon,
} from "@heroicons/vue/solid";
export default {
  components: {
    VolumeUpIcon,
    CalculatorIcon,
    PhoneMissedCallIcon,
    UserRemoveIcon,
    VolumeOffIcon,
    PauseIcon,
    PlayIcon,
    StatusOnlineIcon,
    StatusOfflineIcon,
  },
  props: {
    channel: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async kick() {
      this.removeBridge({
        channelId: this.channel.channel_id,
        bridgeId: this.channel.bridge_id,
      });
    },
    async hangupCall() {
      await this.hangup({
        callId: this.channel.id,
        channelId: this.channel.channel_id,
      });
    },
    async record() {
      this.recordCall({
        callId: this.channel.id,
        status: this.channel.recording_status === "on" ? false : true,
      });
    },
    isRoomRecording(brindgeId) {
      const calls = this.callsWithBridge(brindgeId);
      const recording = calls.find((call) => call.recording_status === "on");
      if (recording) {
        return true;
      }
      return false;
    },
    ...mapActions("activeCalls", [
      "removeBridge",
      "hangup",
      "muteCall",
      "setDMTF",
      "recordCall",
      "pauseRecording",
      "muteRecording",
    ]),
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    ...mapGetters("activeCalls", ["callsWithBridge"]),
  },
};
</script>
