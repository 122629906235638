<template>
  <tr>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex">
        <div>
          <div class="text-sm font-medium text-gray-900">
            <span>{{ call.caller_id_name ?? "N/A" }}</span>
          </div>
          <div
            class="text-sm text-gray-500 w-40 overflow-x-hidden"
            :title="call.caller_id_num"
          >
            (DID:
            {{
              userType === "listen_only" && call.number.length > 3
                ? call.number.substring(0, 3) +
                  new Array(call.number.length - 3).join("*")
                : call.number
            }})
          </div>
        </div>
      </div>
    </td>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex justify-center text-sm text-gray-500">
        {{ created }}
      </div>
    </td>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex justify-center items-center">
        <div>
          <div class="text-sm flex text-gray-500">
            <button
              v-if="userType !== 'listen_only'"
              @click="$emit('call_back', call)"
              type="button"
              class="
                inline-flex
                items-center
                px-2.5
                py-1.5
                border border-gray-300
                shadow-sm
                text-xs
                font-medium
                rounded
                text-gray-700
                bg-white
                hover:bg-gray-50
                focus:outline-none
              "
            >
              CALL BACK
            </button>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import StorageUtil from "@/utils/LocalStorageUtil.js";
export default {
  emits: ["call_back"],
  props: {
    call: {
      type: Object,
      default: null,
    },
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    created() {
      const date = new Date(this.call.end_at * 1000);
      return date.toLocaleString("en-US", { timeZone: "EST" });
    },
  },
};
</script>
