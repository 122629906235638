<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ call.number }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ call.notes }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
      <button
        v-if="userType !== 'listen_only'"
        @click="callItem"
        title="Call"
        type="button"
        class="
          inline-flex
          ml-2
          items-center
          p-1
          border border-transparent
          rounded-full
          shadow-sm
          text-indigo-700
          bg-indigo-100
          hover:bg-indigo-200
          focus:outline-none
        "
      >
        <PhoneIcon class="h-5 w-5" aria-hidden="true" />
      </button>
      <button
        v-if="userType !== 'listen_only'"
        @click="updateItemStatus"
        title="Update Status"
        type="button"
        class="
          inline-flex
          ml-2
          items-center
          p-1
          border border-transparent
          rounded-full
          shadow-sm
          text-indigo-700
          bg-indigo-100
          hover:bg-indigo-200
          focus:outline-none
        "
      >
        <SwitchVerticalIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </td>
  </tr>
</template>

<script>
import { PhoneIcon, SwitchVerticalIcon } from "@heroicons/vue/solid";
import StorageUtil from "@/utils/LocalStorageUtil";

export default {
  props: {
    call: {
      type: Object,
      default: null,
    },
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
  },
  components: {
    PhoneIcon,
    SwitchVerticalIcon,
  },
  emits: ["call", "updateStatus"],
  methods: {
    callItem() {
      this.$emit("call", this.call);
    },
    updateItemStatus() {
      this.$emit("updateStatus", this.call);
    },
  },
};
</script>
