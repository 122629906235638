<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="$emit('close')"
      :open="opened"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6
            "
          >
            <div class="bg-white">
              <div class="">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Create New Room
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Enter the name of the room</p>
                </div>
                <form
                  @submit="createNewRoom"
                  class="mt-5 sm:flex sm:items-center"
                >
                  <div class="w-full sm:max-w-xs">
                    <label for="roomName" class="sr-only">name</label>
                    <input
                      v-model="roomName"
                      type="text"
                      id="roomName"
                      name="roomName"
                      class="
                        shadow-sm
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                      placeholder="Room Name"
                    />
                  </div>
                  <div class="ml-2 -mt-1 w-full sm:max-w-xs">
                    <label for="roomType" class="sr-only">Type</label>
                    <select
                      id="roomType"
                      v-model="roomType"
                      class="
                        mt-1
                        block
                        w-full
                        py-2
                        px-3
                        border border-gray-300
                        bg-white
                        rounded-md
                        shadow-sm
                        focus:outline-none
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                      "
                    >
                      <option value="mixing,dtmf_events">Mixing</option>
                      <option value="holding,dtmf_events">Holding</option>
                    </select>
                  </div>
                  <button
                    :disabled="isAddingRoom"
                    type="submit"
                    class="
                      mt-3
                      w-full
                      inline-flex
                      items-center
                      justify-center
                      px-4
                      py-2
                      border border-transparent
                      shadow-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-gray-700
                      hover:bg-gray-800
                      focus:outline-none
                      sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                    "
                  >
                    {{ isAddingRoom ? "wait..." : "Create" }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { mapActions } from "vuex";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isAddingRoom: false,
      roomName: "",
      roomType: "mixing,dtmf_events",
    };
  },
  methods: {
    async createNewRoom(e) {
      e.preventDefault();
      if (this.roomName !== "") {
        this.$Progress.start();
        this.isAddingRoom = true;
        await this.create({
          roomName: this.roomName,
          roomType: this.roomType,
        });
        this.roomName = "";
        this.isAddingRoom = false;
        this.$emit("close");
        this.$Progress.finish();
      }
    },
    ...mapActions("rooms", ["create"]),
  },
};
</script>
