<template>
  <thead class="bg-gray-100">
    <tr>
      <th
        scope="col"
        class="
          px-3
          py-3
          text-xs
          font-medium
          text-gray-500
          uppercase
          text-left
          tracking-wider
        "
      >
        Caller
      </th>
      <th
        scope="col"
        class="
          px-3
          py-3
          text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Number
      </th>
      <th
        scope="col"
        class="
          px-3
          py-3
          text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Action
      </th>
    </tr>
  </thead>
</template>

<script>
export default {};
</script>
