<template>
  <div>
    <TransitionRoot as="template" :show="opened">
      <Dialog
        as="div"
        static
        class="fixed z-10 inset-0 overflow-y-auto"
        :open="opened"
        @close="close"
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="inline-block align-middle h-screen" aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                overflow-hidden
                transform
                transition-all
                sm:my-8 sm:align-middle sm:p-6
              "
            >
              <div class="hidden sm:block absolute top-0 right-0 pt-3 pr-3">
                <button
                  @click="close"
                  type="button"
                  class="
                    bg-white
                    rounded-md
                    text-gray-400
                    hover:text-gray-500
                    focus:outline-none
                  "
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div class="bg-white mt-6">
                <div>
                  <input
                    v-model="dmtf"
                    disabled
                    type="text"
                    class="
                      w-full
                      mx-auto
                      shadow-sm
                      focus:ring-transparent focus:border-transparent
                      block
                      text-sm
                      border-transparent
                      bg-gray-200
                      rounded-md
                    "
                    placeholder="123.."
                  />
                  <div class="mt-5">
                    <ul class="w-56 mx-auto grid gap-4 grid-cols-3">
                      <li v-for="i in 9" :key="i" class="col-span-1">
                        <div
                          class="flex items-center justify-center rounded-full"
                        >
                          <button
                            @click="tapped(i + '')"
                            type="button"
                            class="
                              bg-gray-800
                              hover:bg-gray-700
                              w-14
                              h-14
                              rounded-full
                              text-white
                              focus:outline-none
                              font-medium
                            "
                          >
                            {{ i }}
                          </button>
                        </div>
                      </li>

                      <li class="col-span-1">
                        <div
                          class="flex items-center justify-center rounded-full"
                        >
                          <button
                            @click="tapped('*')"
                            type="button"
                            class="
                              bg-gray-800
                              hover:bg-gray-700
                              w-14
                              h-14
                              rounded-full
                              text-white
                              focus:outline-none
                            "
                          >
                            *
                          </button>
                        </div>
                      </li>
                      <li class="col-span-1">
                        <div
                          class="flex items-center justify-center rounded-full"
                        >
                          <button
                            @click="tapped('0')"
                            type="button"
                            class="
                              bg-gray-800
                              hover:bg-gray-700
                              w-14
                              h-14
                              rounded-full
                              text-white
                              focus:outline-none
                            "
                          >
                            0
                          </button>
                        </div>
                      </li>
                      <li class="col-span-1">
                        <div
                          class="flex items-center justify-center rounded-full"
                        >
                          <button
                            @click="tapped('#')"
                            type="button"
                            class="
                              bg-gray-800
                              hover:bg-gray-700
                              w-14
                              h-14
                              rounded-full
                              text-white
                              focus:outline-none
                            "
                          >
                            #
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { XIcon } from "@heroicons/vue/outline";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dmtf: "",
    };
  },
  methods: {
    close() {
      this.setDMTF({ callId: null });
      this.dmtf = "";
    },
    async tapped(num) {
      this.dmtf += num;
      this.$Progress.start();
      await this.sendDTMF({ dtmf: num });
      this.$Progress.finish();
    },
    ...mapActions("activeCalls", ["setDMTF", "sendDTMF"]),
  },
  computed: {
    ...mapGetters("activeCalls", ["dmtfNumber"]),
  },
};
</script>
