<template>
  <div>
    <TransitionRoot as="template" :show="opened">
      <Dialog
        as="div"
        static
        class="fixed z-10 inset-0 overflow-y-auto"
        :open="opened"
        @close="close"
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="inline-block align-middle h-screen" aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                overflow-hidden
                transform
                transition-all
                sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6
              "
            >
              <div class="bg-white">
                <div class="">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Place New Call
                  </h3>
                  <div class="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      You can enter multiple phones using comma or new line.
                    </p>
                  </div>
                  <form @submit="action">
                    <div class="mt-3 space-y-3">
                      <div
                        class="
                          sm:pt-3 sm:border-t sm:border-gray-200 sm:col-span-3
                        "
                      >
                        <label class="block text-sm font-medium text-gray-700">
                          Phone Numbers
                        </label>
                        <div class="mt-1">
                          <textarea
                            @focusout="unfocused"
                            v-model="numbersString"
                            rows="2"
                            class="
                              max-h-36
                              shadow-sm
                              block
                              w-full
                              focus:ring-indigo-500 focus:border-indigo-500
                              sm:text-sm
                              border-gray-300
                              rounded-md
                            "
                            placeholder="Phone Numbers"
                          />
                        </div>
                      </div>

                      <div class="sm:border-gray-200 sm:col-span-3">
                        <SwitchGroup
                          as="div"
                          class="flex items-center justify-between"
                        >
                          <span class="flex-grow flex flex-col">
                            <SwitchLabel
                              as="span"
                              class="text-sm font-medium text-gray-900"
                              passive
                              >Silent Join</SwitchLabel
                            >
                            <SwitchDescription
                              as="span"
                              class="text-sm text-gray-500"
                              >If silent join, you have to select a
                              room.</SwitchDescription
                            >
                          </span>
                          <Switch
                            v-model="silentJoin"
                            :class="[
                              silentJoin ? 'bg-indigo-600' : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              :class="[
                                silentJoin ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            />
                          </Switch>
                        </SwitchGroup>
                      </div>

                      <div class="sm:border-gray-200 sm:col-span-3">
                        <label class="block text-sm font-medium text-gray-700">
                          Default Room
                        </label>
                        <div class="mt-1">
                          <select
                            v-model="defaultRoom"
                            class="
                              w-full
                              block
                              py-2
                              px-3
                              border border-gray-300
                              bg-white
                              rounded-md
                              shadow-sm
                              focus:outline-none
                              focus:ring-indigo-500
                              focus:border-indigo-500
                              sm:text-sm
                            "
                          >
                            <option value="">None</option>
                            <option
                              v-for="room in rooms"
                              :key="room.bridgeId"
                              :value="room.bridgeId"
                            >
                              {{ room.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!--
                      <div class="sm:border-gray-200 sm:col-span-3">
                        <label class="block text-sm font-medium text-gray-700">
                          Session
                        </label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                          <div
                            class="relative flex items-stretch flex-grow focus-within:z-10"
                          >
                            <input
                              disabled
                              v-model="session"
                              type="text"
                              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                            />
                          </div>
                          <button
                            :disabled="sessionLoading"
                            @click="createNewSession"
                            type="button"
                            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                          >
                            <span>{{
                              sessionLoading ? "Wait.." : "New Session"
                            }}</span>
                          </button>
                        </div>
                      </div> -->

                      <div class="sm:border-gray-200 sm:col-span-3">
                        <label class="block text-sm font-medium text-gray-700">
                          Caller Name
                        </label>
                        <div class="mt-1">
                          <input
                            v-model="callerName"
                            type="text"
                            placeholder="(Optional)"
                            class="
                              shadow-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              block
                              w-full
                              sm:text-sm
                              border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>

                      <div class="sm:border-gray-200 sm:col-span-3">
                        <label class="block text-sm font-medium text-gray-700">
                          Generated Data
                        </label>
                        <div class="mt-2 h-44 overflow-y-auto scroll">
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  class="
                                    px-6
                                    py-3
                                    text-left text-xs
                                    font-medium
                                    text-gray-500
                                    uppercase
                                    tracking-wider
                                  "
                                >
                                  Number
                                </th>
                                <th
                                  scope="col"
                                  class="
                                    px-6
                                    py-3
                                    text-left text-xs
                                    font-medium
                                    text-gray-500
                                    uppercase
                                    tracking-wider
                                  "
                                >
                                  Caller ID
                                </th>
                                <th
                                  scope="col"
                                  class="
                                    px-6
                                    py-3
                                    text-left text-xs
                                    font-medium
                                    text-gray-500
                                    uppercase
                                    tracking-wider
                                  "
                                >
                                  Room
                                </th>
                                <th
                                  scope="col"
                                  class="
                                    px-6
                                    py-3
                                    text-left text-xs
                                    font-medium
                                    text-gray-500
                                    uppercase
                                    tracking-wider
                                  "
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr
                                v-for="(generate, index) in generated"
                                :key="index"
                              >
                                <td
                                  class="
                                    px-2
                                    py-4
                                    whitespace-nowrap
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  <input
                                    v-model="generate.number"
                                    type="text"
                                    class="
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      block
                                      w-36
                                      rounded-md
                                      text-sm
                                      border-gray-300
                                    "
                                    placeholder="Called Number"
                                  />
                                  <p
                                    v-if="generate.destination"
                                    class="
                                      whitespace-nowrap
                                      text-sm text-gray-500
                                    "
                                  >
                                    {{ generate.destination }}
                                  </p>
                                </td>
                                <td
                                  class="
                                    px-2
                                    py-4
                                    whitespace-nowrap
                                    text-sm text-gray-500
                                    inline-flex
                                  "
                                >
                                  <input
                                    v-model="generate.callerId"
                                    type="text"
                                    class="
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      block
                                      w-36
                                      rounded-md
                                      text-sm
                                      border-gray-300
                                    "
                                    placeholder="Caller Number"
                                  />
                                  <Menu as="span" class="relative block">
                                    <MenuButton
                                      class="
                                        border-2 border-l-0
                                        inline-flex
                                        items-center
                                        px-2
                                        py-2
                                        rounded-r-md
                                        text-sm
                                        font-medium
                                        hover:bg-gray-100
                                        focus:outline-none
                                      "
                                    >
                                      <span class="sr-only">Open options</span>
                                      <ChevronDownIcon
                                        class="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </MenuButton>
                                    <transition
                                      enter-active-class="transition ease-out duration-100"
                                      enter-from-class="transform opacity-0 scale-95"
                                      enter-to-class="transform opacity-100 scale-100"
                                      leave-active-class="transition ease-in duration-75"
                                      leave-from-class="transform opacity-100 scale-100"
                                      leave-to-class="transform opacity-0 scale-95"
                                    >
                                      <MenuItems
                                        class="
                                          z-50
                                          origin-top-right
                                          absolute
                                          right-0
                                          mt-2
                                          -mr-1
                                          rounded-md
                                          shadow-lg
                                          bg-white
                                          ring-1 ring-black ring-opacity-5
                                          focus:outline-none
                                        "
                                      >
                                        <div class="py-1">
                                          <MenuItem
                                            v-for="DID in DIDs"
                                            :key="DID.did_number"
                                            v-slot="{ active }"
                                            @click="
                                              generate.callerId = DID.did_number
                                            "
                                          >
                                            <a
                                              href="#"
                                              :class="[
                                                active
                                                  ? 'bg-gray-100 text-gray-900'
                                                  : 'text-gray-700',
                                                DID.outreach
                                                  ? 'text-yellow-500'
                                                  : '',
                                                'block px-4 py-2 text-sm',
                                              ]"
                                            >
                                              {{ DID.did_number }} ({{
                                                DID.mailbox_name
                                              }})
                                            </a>
                                          </MenuItem>
                                        </div>
                                      </MenuItems>
                                    </transition>
                                  </Menu>
                                </td>
                                <td
                                  class="
                                    px-2
                                    py-4
                                    whitespace-nowrap
                                    text-sm text-gray-500
                                  "
                                >
                                  <select
                                    v-model="generate.room"
                                    class="
                                      w-40
                                      block
                                      py-2
                                      px-3
                                      border border-gray-300
                                      bg-white
                                      rounded-md
                                      shadow-sm
                                      focus:outline-none
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      sm:text-sm
                                    "
                                  >
                                    <option value="">None</option>
                                    <option
                                      v-for="room in rooms"
                                      :key="room.bridgeId"
                                      :value="room.bridgeId"
                                    >
                                      {{ room.name }}
                                    </option>
                                  </select>
                                </td>
                                <td
                                  class="
                                    px-2
                                    py-4
                                    whitespace-nowrap
                                    text-sm text-gray-500
                                  "
                                >
                                  {{ generate.status }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="">
                        <div
                          v-if="silentRoomError"
                          class="bg-red-50 p-4 mb-2 rounded-lg"
                        >
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <XCircleIcon
                                class="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div class="ml-3">
                              <h3 class="text-sm font-medium text-red-800">
                                If you select silent join all numbers should
                                have a room.
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-end">
                          <button
                            :disabled="isGenerating || isPlacing"
                            @click="close"
                            type="button"
                            class="
                              bg-white
                              py-2
                              px-4
                              border border-gray-300
                              rounded-md
                              shadow-sm
                              text-sm
                              font-medium
                              text-gray-700
                              hover:bg-gray-50
                              focus:outline-none
                              focus:ring-2
                              focus:ring-offset-2
                              focus:ring-indigo-500
                            "
                          >
                            Cancel
                          </button>
                          <button
                            :disabled="isGenerating || isPlacing"
                            type="submit"
                            class="
                              ml-3
                              inline-flex
                              justify-center
                              py-2
                              px-4
                              border border-transparent
                              shadow-sm
                              text-sm
                              font-medium
                              rounded-md
                              text-white
                              bg-indigo-600
                              hover:bg-indigo-700
                              focus:outline-none
                              focus:ring-2
                              focus:ring-offset-2
                              focus:ring-indigo-500
                            "
                          >
                            {{ isGenerating ? "Wait.." : "Generate" }}
                          </button>

                          <button
                            :disabled="
                              isGenerating ||
                              isPlacing ||
                              generated.length === 0
                            "
                            type="button"
                            @click="place"
                            class="
                              ml-3
                              inline-flex
                              justify-center
                              py-2
                              px-4
                              border border-transparent
                              shadow-sm
                              text-sm
                              font-medium
                              rounded-md
                              text-white
                              bg-indigo-600
                              hover:bg-indigo-700
                              focus:outline-none
                              focus:ring-2
                              focus:ring-offset-2
                              focus:ring-indigo-500
                            "
                          >
                            {{ isPlacing ? "Wait.." : "Place" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/vue/solid";
export default {
  components: {
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    XCircleIcon,
    ChevronDownIcon,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    recall: {
      type: Object,
      default: null,
    },
  },
  emits: ["close"],
  data() {
    return {
      //session: "",
      //sessionLoading: false,

      numbersString: "",
      callerName: "",
      defaultRoom: "x",
      silentJoin: false,
      generated: [],
      silentRoomError: false,

      isGenerating: false,
      isPlacing: false,

      oldGeneratedData: "",

      isRecall: false,
    };
  },

  async created() {
    //this.defaultRoom = this.rooms[0]?.bridgeId;
    //const { data } = await this.getSession();
    //this.session = data.name.replace("ses", "session");
  },

  methods: {
    // async createNewSession() {
    //   this.sessionLoading = true;
    //   const { data } = await this.createSession();
    //   this.session = data.name.replace("ses", "session");
    //   this.sessionLoading = false;
    // },

    unfocused() {
      if (this.isRecall) return;
      this.action({
        preventDefault: () => {},
      });
    },

    action(e) {
      e.preventDefault();
      if (this.isGenerating) return;
      if (this.numbersString === "") return;

      this.silentRoomError = false;

      if (this.silentJoin && this.defaultRoom === "") {
        this.silentRoomError = true;
        return;
      }

      let numbers = this.numbersString.trim().replace(/\n/g, ",").split(",");
      // if (JSON.stringify(numbers) === JSON.stringify(this.oldGeneratedData))
      //   return;

      this.generated = [];
      this.generated = [...new Set(numbers)].map((num) => {
        return {
          number: num,
          callerId: "",
          room: this.defaultRoom,
          status: "Generating..",
          destination: false,
        };
      });

      this.oldGeneratedData = numbers;

      this.startGenerating();
    },

    async startGenerating() {
      this.$Progress.start();
      this.isGenerating = true;
      for (let generate of this.generated) {
        try {
          let result = await this.generate({ number: generate.number });
          generate.callerId = result?.callerid;
          generate.destination = result?.destination;
          generate.status = result?.callerid
            ? "Generated"
            : "Unable to generate";
        } catch (_) {
          generate.callerId = "";
          generate.destination = false;
          generate.status = "Unable to generate";
        }
      }
      this.isGenerating = false;
      this.$Progress.finish();
    },

    async place() {
      this.silentRoomError = false;

      for (let generate of this.generated) {
        if (this.silentJoin && generate.room === "") {
          this.silentRoomError = true;
        }
      }

      if (this.silentRoomError) return;

      let isValid = true;
      for (let generate of this.generated) {
        if (!generate.callerId || generate.callerId.trim().length < 3) {
          isValid = false;
        }
      }

      if (!isValid) {
        alert("Please enter caller ID for all generated numbers.");
        return;
      }

      this.$Progress.start();
      this.isPlacing = true;
      for (let generate of this.generated) {
        generate.status = "Placing..";
      }

      for (let generate of this.generated) {
        let result = await this.placeCall({
          number: generate.number,
          callerIdNumber: generate.callerId,
          callerIdName: this.callerName,
          bridgeId: generate.room,
          silentJoin: this.silentJoin,
        });
        generate.status = "Placed";
        this.appendCall({ call: result });
      }

      this.isPlacing = false;
      this.$Progress.finish();
      this.close();
    },

    close() {
      this.numbersString = "";
      this.defaultRoom = this.rooms[0]?.bridgeId;
      this.callerName = "";
      this.generated = [];
      this.isGenerating = false;
      this.isPlacing = false;
      this.silentJoin = false;
      this.$emit("close");
    },

    prepareWithNumbers(phoneNumber, DIDNumber = null) {
      this.isRecall = true;
      this.defaultRoom = this.rooms[0]?.bridgeId;
      this.numbersString = phoneNumber;
      if (DIDNumber) {
        this.generated = [
          {
            number: phoneNumber,
            callerId: DIDNumber,
            room: this.defaultRoom ?? this.rooms[0]?.bridgeId,
            status: "Generated",
          },
        ];
      } else {
        this.action({
          preventDefault: () => {},
        });
      }
    },

    prepare(call) {
      this.isRecall = call ? true : false;
      if (!call) {
        this.defaultRoom = this.rooms[0]?.bridgeId;
        return;
      }
      this.numbersString = call.number;
      this.callerName = call.caller_id_name;
      let room = this.rooms[0]?.bridgeId;
      const roomFound = this.rooms.find(
        (room) => room.bridgeId === call.bridge_id
      );
      if (roomFound) {
        room = call.bridge_id;
      }
      this.defaultRoom = room;
      this.generated = [
        {
          number: call.number,
          callerId: call.caller_id_num,
          room: room,
          status: "Generated",
        },
      ];
    },

    prepare_call_back(call) {
      this.isRecall = call ? true : false;
      if (!call) {
        this.defaultRoom = this.rooms[0]?.bridgeId;
        return;
      }
      this.numbersString = call.caller_id_num;
      this.callerName = null;
      let room = this.rooms[0]?.bridgeId;
      const roomFound = this.rooms.find(
        (room) => room.bridgeId === call.bridge_id
      );
      if (roomFound) {
        room = call.bridge_id;
      }
      this.defaultRoom = room;
      this.generated = [
        {
          number: call.caller_id_num,
          callerId: call.number,
          room: room,
          status: "Generated",
        },
      ];
    },

    ...mapActions("place", [
      "generate",
      "placeCall",
      //"getSession",
      //"createSession",
    ]),
    ...mapActions("activeCalls", ["appendCall"]),
  },
  computed: {
    ...mapGetters("roomsStore", ["rooms"]),
    ...mapGetters("calls", ["DIDs"]),
  },
};
</script>

<style>
.scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
.scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
.scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
</style>
