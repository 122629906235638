<template>
  <div class="px-3 py-4 flex justify-between items-center">
    <span class="text-lg leading-6 font-medium text-gray-900">
      {{ label }}
    </span>
    <span v-if="loading" class="relative inline-block">
      <ClockIcon
        class="animate-spin h-5 w-5 text-gray-700"
        aria-hidden="true"
      />
    </span>
  </div>
</template>

<script>
import { ClockIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ClockIcon,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
