<template>
  <div class="mx-3 mb-3">
    <div class="relative flex items-stretch flex-grow focus-within:z-10">
      <div
        class="
          absolute
          inset-y-0
          left-0
          pl-3
          flex
          items-center
          pointer-events-none
        "
      >
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="search"
        class="pl-10 block w-full rounded-md sm:text-sm border-gray-300"
        placeholder="Search"
        @input="searchAction"
        v-model="term"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { SearchIcon } from "@heroicons/vue/solid";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SearchIcon,
  },
  data() {
    return {
      term: "",
    };
  },
  created() {
    this.term = this.search;
  },
  methods: {
    searchAction: _.debounce(function async() {
      this.setSearchTerm({ term: this.term });
      this.listMissedCalls();
    }, 500),

    ...mapActions("missedCalls", ["listMissedCalls", "setSearchTerm"]),
  },
  computed: {
    ...mapGetters("missedCalls", ["loading", "search"]),
  },
};
</script>
