<template>
  <section>
    <div class="rounded-lg bg-white overflow-hidden shadow">
      <div class="px-3 py-4 flex justify-between items-center">
        <span class="text-lg leading-6 font-medium text-gray-900">
          Incoming Calls
        </span>
        <span v-if="false" class="relative inline-block">
          <ClockIcon
            class="animate-spin h-5 w-5 text-gray-700"
            aria-hidden="true"
          />
        </span>
        <span class="relative inline-block">
          <VolumeUpIcon
            @click="setMute({ value: true })"
            v-if="!incomingMuted"
            class="h-5 w-5 text-indigo-700 hover:text-indigo-500 cursor-pointer"
            aria-hidden="true"
          />
          <VolumeOffIcon
            @click="setMute({ value: false })"
            v-if="incomingMuted"
            class="h-5 w-5 text-indigo-700 hover:text-indigo-500 cursor-pointer"
            aria-hidden="true"
          />
        </span>
      </div>

      <div v-if="sessions.length === 0" class="mx-3 mb-2">
        <EmptyTable label="No incoming calls" />
      </div>

      <div v-if="sessions.length !== 0" class="mx-4 mb-4">
        <CallItem
          v-for="session in sessions"
          :key="session.id"
          :session="session"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import CallItem from "@/modules/incoming_calls/views/incoming_call_item.vue";

import { ClockIcon, VolumeUpIcon, VolumeOffIcon } from "@heroicons/vue/solid";

export default {
  components: {
    EmptyTable,
    CallItem,
    ClockIcon,
    VolumeUpIcon,
    VolumeOffIcon,
  },
  created() {
    this.listCalls();
  },
  methods: {
    ...mapActions("incomingCalls", ["setMute", "listCalls"]),
  },
  computed: {
    ...mapGetters("incomingCalls", ["sessions", "incomingMuted"]),
  },
};
</script>
