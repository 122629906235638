<template>
  <section>
    <div class="rounded-lg bg-white overflow-hidden shadow">
      <SectionHeader label="Missed Calls" :loading="loading" />

      <SearchBox />

      <div v-if="calls.length === 0" class="mx-3 mb-2">
        <EmptyTable label="You do not have any missed calls." />
      </div>

      <div v-if="calls.length > 0">
        <table class="min-w-full divide-y divide-gray-200">
          <MissedCallTableHeader />
          <tbody class="bg-white divide-y divide-gray-200">
            <MissedCallItem
              v-for="call in calls"
              :key="call.id"
              :call="call"
              @call_back="call_back"
            />
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>

    <PlaceCallDialog
      ref="dialog"
      @close="placeCallDialog = false"
      :opened="placeCallDialog"
    />
  </section>
</template>

<script>
import SectionHeader from "@/modules/common_views/section_header.vue";
import MissedCallItem from "@/modules/missed_calls/views/missed_call_item.vue";
import MissedCallTableHeader from "@/modules/missed_calls/views/missed_call_table_header.vue";
import SearchBox from "@/modules/missed_calls/views/search_box.vue";
import Pagination from "@/modules/missed_calls/views/pagination.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import PlaceCallDialog from "@/widgets/livecall/rooms/active-room/PlaceCallDialog.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SectionHeader,
    EmptyTable,
    MissedCallItem,
    MissedCallTableHeader,
    SearchBox,
    Pagination,
    PlaceCallDialog,
  },
  data() {
    return {
      placeCallDialog: false,
    };
  },
  created() {
    this.listMissedCalls();
  },
  methods: {
    call_back(call) {
      this.placeCallDialog = true;
      this.$refs.dialog.prepare_call_back(call);
    },
    ...mapActions("missedCalls", [
      "listMissedCalls",
      "updatePage",
      "setSearchTerm",
    ]),
  },
  computed: {
    ...mapGetters("missedCalls", ["calls", "loading"]),
  },
};
</script>
