import axiosInstance, { BRIDGE_CALL_URL } from "../config.js";

export default {
  placeCall(
    fromCallerIdName,
    fromCallerIdNumber,
    fromNumber,
    toCallerIdName,
    toCallerIdNumber,
    toNumber,
    record
  ) {
    return axiosInstance.post(`${BRIDGE_CALL_URL}`, {
      fromCallerIdName,
      fromCallerIdNumber,
      fromNumber,
      toCallerIdName,
      toCallerIdNumber,
      toNumber,
      record,
    });
  },
};
