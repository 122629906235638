<template>
  <div>
    <TransitionRoot as="template" :show="opened">
      <Dialog
        as="div"
        static
        class="fixed z-10 inset-0 overflow-y-auto"
        :open="opened"
        @close="close"
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="inline-block align-middle h-screen" aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                overflow-hidden
                transform
                transition-all
                sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6
              "
            >
              <div class="bg-white">
                <div class="">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Place Bridge Call
                  </h3>
                  <form @submit="action">
                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div class="grid grid-cols-3">
                        <div class="sm:border-gray-200 mr-4 mb-4">
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            Your Number
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="fromNumber"
                              type="text"
                              placeholder="Number to call first"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>

                        <div class="sm:border-gray-200 mr-4 mb-4">
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            Your Name
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="fromName"
                              type="text"
                              placeholder="Optional"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>

                        <div class="sm:border-gray-200 mr-4 mb-4">
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            From Caller ID
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="fromCallerId"
                              type="text"
                              placeholder="Optional"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>

                        <div class="sm:border-gray-200 mr-4 mb-6">
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            To Number
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="toNumber"
                              type="text"
                              placeholder="123456789"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>

                        <div class="sm:border-gray-200 mr-4">
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            To Name
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="toName"
                              type="text"
                              placeholder="(Optional)"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>

                        <div class="sm:border-gray-200 mr-4 mb-4">
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            To Caller ID
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="toCallerId"
                              type="text"
                              placeholder="Optional"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>

                        <div class="sm:border-gray-200 mr-4 ml-1">
                          <SwitchGroup
                            as="div"
                            class="flex items-center justify-between"
                          >
                            <span class="flex-grow flex flex-col">
                              <SwitchLabel
                                as="span"
                                class="text-sm font-medium text-gray-900"
                                passive
                                >Record The Call</SwitchLabel
                              >
                            </span>
                            <Switch
                              v-model="record"
                              :class="[
                                record ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                              ]"
                            >
                              <span
                                aria-hidden="true"
                                :class="[
                                  record ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                ]"
                              />
                            </Switch>
                          </SwitchGroup>
                        </div>
                      </div>

                      <div class="">
                        <div class="flex justify-end">
                          <button
                            :disabled="isPlacing"
                            @click="close"
                            type="button"
                            class="
                              bg-white
                              py-2
                              px-4
                              border border-gray-300
                              rounded-md
                              shadow-sm
                              text-sm
                              font-medium
                              text-gray-700
                              hover:bg-gray-50
                              focus:outline-none
                            "
                          >
                            Cancel
                          </button>

                          <button
                            :disabled="isGenerating || isPlacing"
                            type="button"
                            @click="startGenerating"
                            class="
                              ml-3
                              inline-flex
                              justify-center
                              py-2
                              px-4
                              border border-transparent
                              shadow-sm
                              text-sm
                              font-medium
                              rounded-md
                              text-white
                              bg-indigo-600
                              hover:bg-indigo-700
                              focus:outline-none
                            "
                          >
                            {{ isGenerating ? "Wait.." : "Generate" }}
                          </button>

                          <button
                            :disabled="isGenerating || isPlacing"
                            type="button"
                            @click="place"
                            class="
                              ml-3
                              inline-flex
                              justify-center
                              py-2
                              px-4
                              border border-transparent
                              shadow-sm
                              text-sm
                              font-medium
                              rounded-md
                              text-white
                              bg-indigo-600
                              hover:bg-indigo-700
                              focus:outline-none
                            "
                          >
                            {{ isPlacing ? "Wait.." : "Place" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import bridgeCallApi from "@/network/refactored/bridge_calls.js";
export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    recall: {
      type: Object,
      default: null,
    },
  },
  emits: ["close"],
  data() {
    return {
      isPlacing: false,
      isGenerating: false,
      record: false,
      fromNumber: "",
      fromName: "",
      toNumber: "",
      toName: "",
      fromCallerId: "",
      toCallerId: "",
    };
  },

  methods: {
    async startGenerating() {
      this.$Progress.start();
      this.isGenerating = true;

      if (this.fromNumber !== "") {
        const fromCallerIdNumber = await this.generate({
          number: this.fromNumber,
        });
        this.fromCallerId = fromCallerIdNumber?.callerid;
      }

      if (this.toNumber !== "") {
        const toCallerIdNumber = await this.generate({
          number: this.toNumber,
        });
        this.toCallerId = toCallerIdNumber?.callerid;
      }

      this.isGenerating = false;
      this.$Progress.finish();
    },

    async place() {
      if (this.fromNumber === "" || this.toNumber === "") {
        return;
      }

      this.$Progress.start();
      this.isPlacing = true;

      if (this.fromCallerId === "") {
        const fromCallerIdNumber = await this.generate({
          number: this.fromNumber,
        });
        this.fromCallerId = fromCallerIdNumber?.callerid;
      }

      if (this.toCallerId === "") {
        const toCallerIdNumber = await this.generate({
          number: this.toNumber,
        });
        this.toCallerId = toCallerIdNumber?.callerid;
      }

      try {
        await bridgeCallApi.placeCall(
          this.fromName,
          this.fromCallerId,
          this.fromNumber,
          this.toName,
          this.toCallerId,
          this.toNumber,
          this.record
        );
      } catch (e) {
        alert(e?.response?.data?.message ?? "Unable to place the call");
      } finally {
        this.isPlacing = false;
        this.$Progress.finish();
        this.close();
      }
    },

    close() {
      this.isPlacing = false;
      this.record = false;
      this.$emit("close");
    },

    ...mapActions("place", ["generate"]),
  },
};
</script>

<style>
.scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
.scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
.scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
</style>
