<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="$emit('close')"
      :open="opened"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6
            "
          >
            <div class="bg-white">
              <div class="">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Update Statue
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Select the new status of the call</p>
                </div>
                <form
                  @submit="updateCallStatus"
                  class="mt-5 sm:flex sm:items-center"
                >
                  <div class="ml-2 -mt-1 w-full sm:max-w-xs">
                    <label for="callStatus" class="sr-only">Status</label>
                    <select
                      id="callStatus"
                      v-model="status"
                      class="
                        mt-1
                        block
                        w-full
                        py-2
                        px-3
                        border border-gray-300
                        bg-white
                        rounded-md
                        shadow-sm
                        focus:outline-none
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                      "
                    >
                      <option
                        v-if="currentStatus !== 'created'"
                        value="created"
                      >
                        Created
                      </option>
                      <option
                        v-if="currentStatus !== 'on_hold'"
                        value="on_hold"
                      >
                        On Hold
                      </option>
                      <option
                        v-if="currentStatus !== 'completed'"
                        value="completed"
                      >
                        Completed
                      </option>
                      <option
                        v-if="currentStatus !== 'cancelled'"
                        value="cancelled"
                      >
                        Cancelled
                      </option>
                    </select>
                  </div>
                  <button
                    :disabled="isUpdatingStatus"
                    type="submit"
                    class="
                      mt-3
                      w-full
                      inline-flex
                      items-center
                      justify-center
                      px-4
                      py-2
                      border border-transparent
                      shadow-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-gray-700
                      hover:bg-gray-800
                      focus:outline-none
                      sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                    "
                  >
                    {{ isUpdatingStatus ? "wait..." : "Update" }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { mapActions } from "vuex";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import queuesApi from "@/network/refactored/queues";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    currentStatus: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isUpdatingStatus: false,
      status: this.currentStatus === "completed" ? "on_hold" : "completed",
      call: null,
    };
  },
  methods: {
    setCall(call) {
      this.call = call;
    },
    async updateCallStatus(e) {
      e.preventDefault();
      if (this.status !== "") {
        this.$Progress.start();
        this.isUpdatingStatus = true;
        this.call.status = this.status;
        await queuesApi.updateQueueCallStatus(this.call.id, this.status);
        this.editQueueCall({ queueCall: this.call });
        this.isUpdatingStatus = false;
        this.$emit("close");
        this.$Progress.finish();
      }
    },
    ...mapActions("queues", ["editQueueCall"]),
  },
};
</script>
