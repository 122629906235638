<template>
  <tr>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex justify-center items-center">
        <span
          v-if="!isValidBridge"
          class="
            mr-2
            inline-flex
            items-center
            justify-center
            h-6
            w-6
            rounded-full
            bg-gray-500
          "
        >
          <span class="text-xs font-medium leading-none text-white"
            ><EyeOffIcon class="h-3 w-3" aria-hidden="true"
          /></span>
        </span>
        <div>
          <div class="text-sm font-medium text-gray-900">
            <span>{{ call.caller_id_name ?? "N/A" }}</span>
          </div>
          <div class="text-sm text-gray-500">{{ call.caller_id_num }}</div>
        </div>
      </div>
    </td>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex justify-center text-sm text-gray-500">
        {{
          userType === "listen_only" && call.number.length > 3
            ? call.number.substring(0, 3) +
              new Array(call.number.length - 3).join("*")
            : call.number
        }}
      </div>
    </td>
    <td class="px-3 py-4 whitespace-nowrap">
      <div class="flex justify-center items-center">
        <div>
          <div class="text-sm flex text-gray-500">
            <span
              v-if="call.isWebRtc"
              class="
                inline-flex
                items-center
                px-2.5
                py-0.5
                rounded-md
                text-sm
                font-medium
                bg-yellow-100
                text-yellow-800
              "
            >
              WEBRTC
            </span>
            <span
              v-if="!call.isWebRtc"
              class="
                inline-flex
                items-center
                px-2.5
                py-0.5
                rounded-md
                text-sm
                font-medium
                bg-yellow-100
                text-yellow-800
              "
            >
              {{ call.call_status }}
            </span>
            <button
              v-if="userType !== 'listen_only'"
              @click="openNotesDialog"
              :title="call.notes"
              type="button"
              class="
                inline-flex
                ml-2
                items-center
                p-1
                border border-transparent
                rounded-full
                shadow-sm
                text-indigo-700
                bg-indigo-100
                hover:bg-indigo-200
                focus:outline-none
              "
            >
              <AnnotationIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              v-if="userType !== 'listen_only'"
              @click="hangupCall"
              title="Hangup"
              type="button"
              class="
                inline-flex
                ml-2
                items-center
                p-1
                border border-transparent
                rounded-full
                shadow-sm
                text-indigo-700
                bg-indigo-100
                hover:bg-indigo-200
                focus:outline-none
              "
            >
              <PhoneMissedCallIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AnnotationIcon, EyeOffIcon } from "@heroicons/vue/solid";
import { PhoneMissedCallIcon } from "@heroicons/vue/solid";
import StorageUtil from "@/utils/LocalStorageUtil.js";
export default {
  components: {
    AnnotationIcon,
    EyeOffIcon,
    PhoneMissedCallIcon,
  },
  props: {
    call: {
      type: Object,
      default: null,
    },
  },
  methods: {
    openNotesDialog() {
      this.setCall({ call: this.call });
    },
    async hangupCall() {
      await this.hangup({
        callId: this.call.id,
        channelId: this.call.channel_id,
      });
    },
    ...mapActions("callNotes", ["setCall"]),
    ...mapActions("activeCalls", ["hangup"]),
    ...mapActions("roomsStore", ["listRooms"]),
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    isValidBridge() {
      const isBridgeValid = this.isBridgeValid(this.call.bridge_id);
      if (!isBridgeValid) {
        this.listRooms();
      }
      return isBridgeValid;
    },
    ...mapGetters("roomsStore", ["isBridgeValid"]),
  },
};
</script>
