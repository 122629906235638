<template>
  <div
    class="
      mt-2
      w-full
      bg-white
      shadow-sm
      rounded-lg
      pointer-events-auto
      flex
      ring-1 ring-black ring-opacity-5
    "
  >
    <div class="w-0 flex-1 py-4 px-2">
      <div class="flex items-start">
        <div class="w-0 flex-1">
          <p class="text-sm font-medium text-gray-900">
            {{ session.number }}
            <span class="text-sm text-gray-500 truncate">
              (CID:{{ session.caller_id_num }})
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="flex border-l border-gray-200">
      <button
        @click="accept"
        class="
          w-full
          border border-transparent
          rounded-none rounded-r-lg
          p-2
          flex
          items-center
          justify-center
          text-xs
          font-bold
          text-indigo-600
          hover:text-indigo-500
          focus:outline-none
        "
      >
        Answer
      </button>
    </div>
    <div class="flex border-l border-gray-200">
      <button
        @click="reject('busy')"
        class="
          w-full
          border border-transparent
          rounded-none rounded-r-lg
          p-2
          flex
          items-center
          justify-center
          text-xs
          font-bold
          text-gray-700
          hover:text-gray-500
          focus:outline-none
        "
      >
        Decline
      </button>
    </div>
    <div class="flex border-l border-gray-200">
      <button
        @click="reject('voicemail')"
        class="
          w-full
          border border-transparent
          rounded-none rounded-r-lg
          p-2
          flex
          items-center
          justify-center
          text-xs
          font-bold
          text-gray-700
          hover:text-gray-500
          focus:outline-none
        "
      >
        Voice Mail
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    session: {
      type: Object,
      default: null,
    },
  },
  methods: {
    accept() {
      // this.session.answer({
      //   mediaConstraints: {
      //     audio: true,
      //     video: false,
      //   },
      // });
      this.removeSession({ session: this.session });
      this.changeBridge({
        channelId: this.session.channel_id,
        oldBridgeId: null,
        newBridgeId: this.rooms[0]?.bridgeId,
        answer: true,
      });
    },
    reject(reason) {
      this.removeSession({ session: this.session });
      this.hangup({
        callId: this.session.id,
        channelId: this.session.channel_id,
        reason: reason,
      });
      //this.session.terminate();
    },
    ...mapActions("activeCalls", ["hangup", "changeBridge"]),
    ...mapActions("incomingCalls", ["removeSession"]),
  },
  computed: {
    ...mapGetters("roomsStore", ["rooms"]),
  },
};
</script>
