<template>
  <section>
    <div class="rounded-lg bg-white overflow-hidden shadow">
      <SectionHeader label="Recent Calls" :loading="loading" />

      <SearchBox />

      <div v-if="calls.length === 0" class="mx-3 mb-2">
        <EmptyTable label="You do not have any recent calls." />
      </div>

      <div v-if="calls.length > 0">
        <table class="min-w-full divide-y divide-gray-200">
          <RecentCallTableHeader />
          <tbody class="bg-white divide-y divide-gray-200">
            <RecentCallItem
              v-for="call in calls"
              :key="call.id"
              :call="call"
              @recall="recall"
            />
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>

    <PlaceCallDialog
      ref="dialog"
      @close="placeCallDialog = false"
      :opened="placeCallDialog"
    />
  </section>
</template>

<script>
import SectionHeader from "@/modules/common_views/section_header.vue";
import RecentCallItem from "@/modules/recent_calls/views/recent_call_item.vue";
import RecentCallTableHeader from "@/modules/recent_calls/views/recent_call_table_header.vue";
import SearchBox from "@/modules/recent_calls/views/search_box.vue";
import Pagination from "@/modules/recent_calls/views/pagination.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import PlaceCallDialog from "@/widgets/livecall/rooms/active-room/PlaceCallDialog.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SectionHeader,
    EmptyTable,
    RecentCallItem,
    RecentCallTableHeader,
    SearchBox,
    Pagination,
    PlaceCallDialog,
  },
  data() {
    return {
      placeCallDialog: false,
    };
  },
  created() {
    //this.updatePage({ page: 1 });
    //this.setSearchTerm({ term: "" });
    this.listCalls();
  },
  methods: {
    recall(call) {
      this.placeCallDialog = true;
      const toCall = { ...call };
      if (toCall.direction === "in") {
        toCall.caller_id_name = null;
        toCall.caller_id_num = call.number;
        toCall.number = call.caller_id_num;
      }
      this.$refs.dialog.prepare(toCall);
    },
    ...mapActions("recentCalls", ["listCalls", "updatePage", "setSearchTerm"]),
  },
  computed: {
    ...mapGetters("recentCalls", ["calls", "loading"]),
  },
};
</script>
