<template>
  <div
    :class="[
      room.expanded ? 'h-full' : 'h-12',
      'px-3 bg-gray-100 rounded-lg overflow-y-hidden',
    ]"
    @drop="onDrop($event)"
    @dragenter.prevent
    @dragover.prevent
  >
    <div @click="toggle()" class="block bg-gray-100 cursor-pointer">
      <div class="py-3 flex items-center">
        <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div class="truncate cursor-pointer">
            <div class="flex items-center text-sm">
              <p class="mr-2 flex-shrink-0 font-normal text-gray-500">
                <span
                  class="
                    px-2
                    inline-flex
                    text-xs
                    leading-5
                    font-semibold
                    rounded-md
                    bg-gray-300
                    text-gray-800
                  "
                >
                  {{ room.bridge_type }}
                </span>
              </p>
              <p class="font-medium text-gray-800 truncate">{{ room.name }}</p>
            </div>
          </div>
          <div class="mt-4 flex-grow sm:mt-0 sm:ml-5">
            <div class="flex overflow-hidden -space-x-1"></div>
          </div>
          <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
            <div class="flex overflow-hidden -space-x-1">
              <a
                href="#"
                class="
                  mr-3
                  relative
                  inline-flex
                  items-center
                  rounded-md
                  border border-gray-300
                  px-3
                  py-0.5
                  text-sm
                "
              >
                <span
                  class="
                    absolute
                    flex-shrink-0 flex
                    items-center
                    justify-center
                  "
                >
                  <span
                    v-if="isRoomRecording"
                    class="
                      animate-ping
                      absolute
                      inline-flex
                      h-2.5
                      w-2.5
                      rounded-full
                      bg-red-500
                      opacity-75
                    "
                  ></span>
                  <span
                    :class="[
                      isRoomRecording ? 'bg-red-500' : 'bg-gray-400',
                      'h-2 w-2 rounded-full',
                    ]"
                    aria-hidden="true"
                  />
                </span>
                <span
                  :class="[
                    isRoomRecording ? 'text-red-500' : 'text-gray-400',
                    'ml-4 font-medium',
                  ]"
                  >REC</span
                >
              </a>

              <span
                class="
                  inline-flex
                  items-center
                  px-2.5
                  py-0.5
                  rounded-md
                  text-sm
                  font-medium
                  bg-indigo-100
                  text-indigo-800
                "
              >
                <svg
                  class="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                  fill="currentColor"
                  viewBox="0 0 8 8"
                >
                  <circle cx="4" cy="4" r="3" />
                </svg>
                {{ calls.length }} Parties
              </span>
            </div>
          </div>
          <div class="ml-3 flex-shrink-0">
            <ChevronDownIcon
              v-if="!room.expanded"
              class="h-6 w-6 text-gray-400"
              aria-hidden="true"
            />
            <ChevronUpIcon
              v-if="room.expanded"
              class="h-6 w-6 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3 flex-shrink-0">
            <TrashIcon
              v-if="false && calls.length === 0 && userType !== 'listen_only'"
              class="h-6 w-6 text-red-400"
              aria-hidden="true"
              @click.stop="deleteBridge()"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="calls.length === 0">
      <EmptyTable label="This room does not have any channels." />
    </div>

    <ul class="flex-1 pb-4">
      <RoomChannel
        v-for="call in calls"
        :key="call.id"
        :channel="call"
        draggable="true"
        @dragstart="startDragging($event, call)"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from "@heroicons/vue/solid";
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import RoomChannel from "@/modules/rooms/views/room_channel.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";

export default {
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    TrashIcon,
    EmptyTable,
    RoomChannel,
  },
  props: {
    room: {
      type: Object,
      default: null,
    },
  },
  emits: ["deleteBridge"],
  methods: {
    startDragging(event, item) {
      if (this.userType === "listen_only") return;
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("bridge_id", item.bridge_id);
      event.dataTransfer.setData("channel_id", item.channel_id);
    },
    async onDrop(event) {
      if (this.userType === "listen_only") return;
      const channelId = event.dataTransfer.getData("channel_id");
      const bridgeId = event.dataTransfer.getData("bridge_id");

      if (bridgeId === this.room.bridgeId) {
        return;
      }

      this.changeBridge({
        channelId: channelId,
        oldBridgeId: bridgeId,
        newBridgeId: this.room.bridgeId,
      });
    },
    toggle() {
      this.toggleExpand({
        bridgeId: this.room.bridgeId,
        expanded: !this.room.expanded,
      });
    },
    deleteBridge() {
      this.$emit("deleteBridge", this.room);
    },

    ...mapActions("activeCalls", ["changeBridge"]),
    ...mapActions("roomsStore", ["toggleExpand"]),
    ...mapActions("rooms", ["deleteRoom"]),
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    isRoomRecording() {
      const calls = this.callsWithBridge(this.room.bridgeId);
      const recording = calls.find((call) => call.recording_status === "on");

      if (recording) {
        return true;
      }
      return false;
    },
    calls() {
      return this.callsWithBridge(this.room.bridgeId);
    },
    ...mapGetters("activeCalls", ["callsWithBridge"]),
  },
};
</script>
