<template>
  <section>
    <ConfirmationDialog
      :open="openDeleteDialog"
      :title="roomToDelete ? 'Delete ' + roomToDelete.name : ''"
      :loading="deleteLoading"
      text="Are you sure you want to delete this room? it will be permanently removed from our servers forever. This action cannot be undone."
      buttonLbl="Delete"
      @cancel="openDeleteDialog = false"
      @confirm="removeRoom"
    />

    <div class="rounded-lg bg-white overflow-hidden shadow">
      <div
        class="
          py-4
          px-4
          flex
          items-center
          justify-between
          flex-wrap
          sm:flex-nowrap
        "
      >
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Active Rooms
        </h3>
        <div class="flex-shrink-0">
          <button
            v-if="false && userType !== 'listen_only'"
            @click="addRoomDialogOpen = true"
            type="button"
            class="
              relative
              inline-flex
              items-center
              px-3
              py-1
              border border-transparent
              shadow-sm
              text-sm
              rounded-md
              text-white
              bg-gray-700
              hover:bg-gray-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-gray-600
            "
          >
            Add Room
          </button>
          <button
            v-if="userType !== 'listen_only'"
            @click="bridgeCallDialog = true"
            type="button"
            class="
              ml-2
              relative
              inline-flex
              items-center
              px-3
              py-1
              border border-transparent
              shadow-sm
              text-sm
              rounded-md
              text-white
              bg-gray-700
              hover:bg-gray-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-gray-600
            "
          >
            Bridge Call
          </button>
          <button
            v-if="userType !== 'listen_only'"
            @click="
              $refs.placedialog.prepare(false);
              placeCallDialog = true;
            "
            type="button"
            class="
              ml-2
              relative
              inline-flex
              items-center
              px-3
              py-1
              border border-transparent
              shadow-sm
              text-sm
              rounded-md
              text-white
              bg-gray-700
              hover:bg-gray-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-gray-600
            "
          >
            Place Call
          </button>
        </div>
      </div>

      <div class="pb-2">
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-hidden border-gray-200">
                <div
                  class="
                    bg-white
                    overflow-hidden
                    sm:rounded-mdx
                    h-96
                    overflow-y-auto
                    scroll
                  "
                >
                  <div class="mx-4 mt-2">
                    <RoomItem
                      v-for="room in rooms"
                      :key="room.bridgeId"
                      :room="room"
                      class="mb-2.5"
                      @deleteBridge="onDeleteRoom"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PlaceCallDialog
    ref="placedialog"
    @close="placeCallDialog = false"
    :opened="placeCallDialog"
  />
  <BridgeCallDialog
    @close="bridgeCallDialog = false"
    :opened="bridgeCallDialog"
  />
  <CreateRoomDialog
    @close="addRoomDialogOpen = false"
    :opened="addRoomDialogOpen"
  />

  <ChannelDMTF :opened="dmtfNumber !== null" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RoomItem from "@/modules/rooms/views/room_item.vue";

import PlaceCallDialog from "@/widgets/livecall/rooms/active-room/PlaceCallDialog.vue";
import BridgeCallDialog from "@/modules/bridge_call/bridge_call.vue";
import ChannelDMTF from "@/widgets/livecall/rooms/active-room/ChannelDMTF.vue";
import CreateRoomDialog from "@/widgets/livecall/rooms/active-room/CreateRoomDialog";
import StorageUtil from "@/utils/LocalStorageUtil.js";
import ConfirmationDialog from "@/widgets/common/ConfirmationDialog";

export default {
  components: {
    RoomItem,
    PlaceCallDialog,
    ChannelDMTF,
    BridgeCallDialog,
    CreateRoomDialog,
    ConfirmationDialog,
  },
  data() {
    return {
      placeCallDialog: false,
      bridgeCallDialog: false,
      addRoomDialogOpen: false,
      openDeleteDialog: false,
      deleteLoading: false,
      roomToDelete: null,
    };
  },
  created() {
    this.listRooms();
  },
  methods: {
    onDeleteRoom(room) {
      this.roomToDelete = room;
      this.openDeleteDialog = true;
    },
    async removeRoom() {
      this.deleteLoading = true;
      await this.deleteRoom({
        roomID: this.roomToDelete.bridgeId,
      });
      this.openDeleteDialog = false;
      this.deleteLoading = false;
    },
    ...mapActions("roomsStore", ["listRooms"]),
    ...mapActions("rooms", ["deleteRoom"]),
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    ...mapGetters("roomsStore", ["rooms"]),
    ...mapGetters("activeCalls", ["dmtfNumber"]),
  },
};
</script>

<style>
.scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}
.scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
.scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
</style>
