<template>
  <section @drop="onDrop($event)" @dragenter.prevent @dragover.prevent>
    <div class="rounded-lg bg-white overflow-hidden shadow">
      <SectionHeader label="Active Calls" :loading="loading" />

      <div v-if="calls.length === 0" class="mx-3 mb-2">
        <EmptyTable label="You do not have any active calls." />
      </div>

      <div v-if="calls.length > 0">
        <table class="min-w-full divide-y divide-gray-200">
          <ActiveCallTableHeader />
          <tbody class="bg-white divide-y divide-gray-200">
            <ActiveCallItem
              v-for="call in calls"
              :key="call.id"
              :call="call"
              draggable="true"
              @dragstart="startDragging($event, call)"
            />
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from "@/modules/common_views/section_header.vue";
import ActiveCallItem from "@/modules/active_calls/views/active_call_item.vue";
import ActiveCallTableHeader from "@/modules/active_calls/views/active_call_table_header.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SectionHeader,
    EmptyTable,
    ActiveCallItem,
    ActiveCallTableHeader,
  },
  created() {
    this.listCalls();
  },
  methods: {
    startDragging(event, item) {
      if (this.userType === "listen_only") return;
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("bridge_id", item.bridge_id);
      event.dataTransfer.setData("channel_id", item.channel_id);
      event.dataTransfer.setData("origin", "ACTIVE_ROOMS");
    },
    async onDrop(event) {
      if (this.userType === "listen_only") return;
      const channelId = event.dataTransfer.getData("channel_id");
      const bridgeId = event.dataTransfer.getData("bridge_id");
      const origin = event.dataTransfer.getData("origin");

      if (origin === "ACTIVE_ROOMS") {
        return;
      }

      this.removeBridge({ channelId, bridgeId });
    },
    ...mapActions("activeCalls", ["listCalls", "removeBridge"]),
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    ...mapGetters("activeCalls", ["calls", "loading"]),
  },
};
</script>
