<template>
  <main class="pb-8">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="pt-4 grid grid-cols-1 gap-4 items-start">
        <div class="grid grid-cols-1 gap-4 lg:col-span-2">
          <RoomsSection />
        </div>
      </div>

      <div
        class="pt-4 grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8"
      >
        <div class="grid grid-cols-1 gap-4 lg:col-span-1">
          <ActiveCallsSection />
        </div>
        <div class="grid grid-cols-1 gap-4">
          <IncomingCallSection v-if="userType !== 'listen_only'" />
        </div>
      </div>
      <div class="pt-4 grid grid-cols-1 gap-4 items-start">
        <div class="grid grid-cols-1 gap-4 lg:col-span-1">
          <QueuesPanel />
        </div>
      </div>
      <div
        class="pt-4 grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8"
      >
        <div class="grid grid-cols-1 gap-4 lg:col-span-1">
          <MissedCallsSection />
        </div>
        <div class="grid grid-cols-1 gap-4">
          <RecentCallsSection />
        </div>
      </div>
    </div>
    <PlaceCallDialog
      ref="dialog"
      @close="placeCallDialog = false"
      :opened="placeCallDialog"
    />

    <CallNotesDialog />
  </main>
</template>

<script>
import { mapActions } from "vuex";
import PlaceCallDialog from "@/widgets/livecall/rooms/active-room/PlaceCallDialog.vue";

import RecentCallsSection from "@/modules/recent_calls/recent_call_panel.vue";
import MissedCallsSection from "@/modules/missed_calls/missed_call_panel.vue";
import ActiveCallsSection from "@/modules/active_calls/active_call_panel.vue";
import RoomsSection from "@/modules/rooms/rooms_panel.vue";
import IncomingCallSection from "@/modules/incoming_calls/incoming_calls.vue";
import CallNotesDialog from "@/modules/call_notes/call_notes_dialog.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";
import QueuesPanel from "@/modules/queues/queues_panel";
export default {
  components: {
    PlaceCallDialog,

    RoomsSection,
    RecentCallsSection,
    MissedCallsSection,
    ActiveCallsSection,
    CallNotesDialog,
    IncomingCallSection,
    QueuesPanel,
  },
  async created() {
    this.$Progress.start();
    await Promise.all([
      this.listActiveCalls(),
      this.listRecentCalls(),
      this.listMissedCalls(),
    ]);
    this.$Progress.finish();
  },
  mounted() {
    if (this.$route.query.phone_number) {
      this.placeCallDialog = true;
      this.$refs.dialog.prepareWithNumbers(
        this.$route.query.phone_number,
        this.$route.query.did_number
      );
    }
  },
  data() {
    return {
      placeCallDialog: false,
    };
  },
  methods: {
    recall(call) {
      this.placeCallDialog = true;
      this.$refs.dialog.prepare(call);
    },
    ...mapActions("channels", [
      "listActiveCalls",
      "listRecentCalls",
      "listMissedCalls",
    ]),
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
  },
};
</script>

<style></style>
